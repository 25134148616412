.loader {
  left: 50%;
  top: 35%;
  position: absolute;
}

.backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  opacity: 0.5;
  z-index: 99999;
}
