@import '../../Themes/_colors.scss';

.file-input {
  &__button {
    align-self: baseline;
  }

  &__input {
    opacity: 0;
    z-index: 2;
    padding: 10px;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &__value {
    align-self: center;
    padding: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__value-container {
    border: 1px solid $border;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    width: 400px;
    margin-right: 15px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }
}
