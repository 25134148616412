.radio-input {
  margin-right: 10px !important;
  margin-top: 3px;
}

.radio-label {
  padding: 7px;
}

textarea {
  font-size: 14px !important;
}

.input-group .form-control {
  z-index: 0;
}
