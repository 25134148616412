@import '../../Themes/_colors.scss';

.virtualTerminal {
  &__frequency {
    margin: 30px 0;

    .title-container {
      margin-bottom: 10px;

      .stroked {
        font-weight: bold;
      }
    }
  }

  &__amount {
    display: flex;
    align-items: center;
    width: 100%;

    &-currency-icon {
      padding: 10px;
      position: absolute;
      top: 22px;
      left: -10px;
    }
  }

  &__container {
    min-width: 600px;
    max-width: 800px;
    width: 100%;
    margin-top: 15px;

    .stroked {
      font-weight: bold;
    }
  }
}

.form-control {
  &__account-number {
    border: 0;
    position: relative;
    padding: 0 !important;
    margin-top: 1px;

    .form-control-feedback {
      display: none;
    }
    .control-highlight {
      border-radius: 4px;
      background-color: transparent;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      pointer-events: none;
      top: 0;
      left: 0;
    }

    input {
      border: 0;
    }
  }
}
