.custom-label {
  width: 100%;
  padding: 8px;
  margin-top: 15px;
}

.custom-button {
  margin: 10px;
}

.custom-input {
  padding: 18px;
}

.ftp-blog {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.375);
  margin: 0;
}
