@import '../../Themes/_colors.scss';

.classy-toggle {
  border: 1px solid $border;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px 30px 15px 30px;
  width: 100%;
  text-align: center;

  &__active {
    background-color: $coral;
    color: white;
  }

  &__left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  &__right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
