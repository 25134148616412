@import '../../Themes/_colors.scss';

.help-container {
  color: $teal;
  align-self: center;

  &__link {
    text-decoration: none;
    color: inherit;
  }
}
