.detailed-errors {
  &__button {
    margin-top: 15px;
  }
  &__errorlist {
    margin-top: 15px;
    border-collapse: collapse;
  }

  &__errorlist td,
  &__errorlist th {
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: top;
  }

  &__errorlist td:first-child {
    width: 250px;
  }

  &__errorlist tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  &__errorlist tr:hover {
    background-color: #ddd;
  }

  &__errorlist th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #999999;
    color: white;
  }
}
