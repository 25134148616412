@import '../../Themes/_colors.scss';
@import '../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
  font-size: 1em;

  &__current-month {
    font-size: 1em;
  }

  &__day,
  &__day-name {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }

  &__header {
    padding-top: 0.8em;
  }

  &__input-container {
    > input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $border;
    }
  }

  &__month {
    margin: 0.4em 1em;
  }

  &__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }

  &__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }

  &__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
}
