@import '../../Themes/_colors.scss';

.offline-donations {
  &__footer-left-button {
    margin-left: 15px;
  }

  &__response-message {
    align-self: center;
    margin-left: 150px;
  }

  &__table {
    .rt-th.-cursor-pointer > div {
      color: white;
      line-height: 2em;
    }
  }

  &__table-cell-icon {
    position: relative;
    right: 20px;
    top: 10px;
  }

  &__table-container {
    margin-top: 30px;
  }

  &__table-header-button {
    margin-right: 15px;
  }

  &__table-header-button-group {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
  }
}
