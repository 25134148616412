@import '../../Themes/_colors.scss';

@mixin focus($borderColor, $shadowColor) {
  [class^='css-'][class$='-a11yText'] {
    & + [class^='css-'][class$='-control'] {
      border-color: $borderColor;
      box-shadow: 0 0 6px $shadowColor;
    }
  }
}

@mixin feedback($borderColor) {
  [class^='css-'][class$='-control'] {
    border-color: $borderColor;
  }
}

.form-group {
  react-select {
    @include focus($lightBlue, $lightBlue);
  }

  &.has-success {
    react-select {
      @include feedback($successText);
      @include focus($successText, $successTextLight);
    }
  }

  &.has-error {
    react-select {
      @include feedback($errorText);
      @include focus($errorText, $errorTextLight);
    }
  }
}
