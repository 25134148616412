@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.classy-button {
  border-radius: 30px;
  padding: 10px 54px;
  color: white;
  align-self: center;

  &__disabled {
    opacity: 0.4;
  }
}
