$active: #eaffe5;
$border: #ccc;
$caution: #f00;
$coral: #f77462;
$dark: #444b55;
$darkBlue: #23527c;
$darkHighlight: #3b4954;
$disabled: #c7c7c7;
$errorText: #a94442;
$errorTextLight: #ce8483;
$inactive: #ffeaea;
$light: #fafafa;
$lightBlue: #66afe9;
$moderateCyan: #50d1bf;
$successText: #3c763d;
$successTextLight: #67b168;
$tableHeaderBackground: #52606b;
$teal: #1ecdb3;
$whitesmoke: #f5f5f5;
