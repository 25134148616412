@import '../../Themes/_colors.scss';

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__header {
    display: flex;
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    font-size: 2.5em;
  }

  &__forgot-password-link {
    margin-top: 20px;
    text-align: center;
  }
}
