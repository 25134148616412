@import '../../Themes/_colors.scss';

.rt-th > div {
  color: $whitesmoke;
}

.blog {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.375);
  margin: 2% 0;
  padding: 2%;
}

.or-blog {
  display: flex;
  justify-content: center;
}

.supporter-search {
  display: flex;
  justify-content: space-between;
}

.supporter-search-modal > .modal-dialog {
  width: auto;
  margin: 5%;
}

.btn-link.text-danger {
  color: $errorText;
}
