#resetPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  span {
    margin: 15px 0;
    text-align: center;
  }
}
