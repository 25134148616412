.form-wrapper {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  button,
  form {
    margin-bottom: 0;
  }

  .user-file {
    margin-top: 15px;
  }

  input[type='file'] {
    margin-bottom: 10px;
  }

  .submit-button {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;
    .checkbox {
      margin-left: 15px;
    }
    .dry-run-help {
      padding-top: 3px;
      padding-left: 4px;
    }
  }

  .template-file {
    margin-left: 8px;
  }
}
