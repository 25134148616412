@import './Themes/_colors.scss';
@import './Styles/Overrides/index.scss';

a {
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

// Buttons -->
button {
  align-self: center;
  background-color: $coral;
  border-radius: 30px;
  color: white;
  padding: 10px 54px;

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    background-color: darken($coral, 5%);
  }

  &.btn-link {
    background: none;
    border: none;
    padding: 0 !important;
    color: #069;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.secondary-button {
  background-color: $moderateCyan;

  &:hover {
    background-color: darken($moderateCyan, 5%);
  }
}

.tertiary-button {
  background-color: $dark;

  &:hover {
    background-color: darken($dark, 5%);
  }
}

.caution-button {
  background-color: $caution;

  &:hover {
    background-color: darken($caution, 5%);
  }
}

.table-button {
  background-color: $light;
  border: 1px solid $dark;
  color: $dark;
  padding: 5px;
  margin: 10px 0;
  width: 120px;

  &:hover {
    background-color: $dark;
    color: white;
  }
}
// <-- Buttons

form {
  margin-bottom: 3em;

  .checkbox {
    float: right;
    margin: 5px 0 5px 0;
  }

  .form-group {
    margin-bottom: 10px;
  }

  textarea {
    min-width: fit-content;
    min-height: fit-content;
    max-width: 100%;
  }
}

input[type='file'] {
  cursor: pointer;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  padding: 18px 12px;
}

.avatar {
  align-self: center;
  font-size: 3em;
  color: $dark;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    cursor: pointer;
  }

  &__name {
    color: $dark;
  }
}

.block {
  display: block;
}

.centered {
  align-items: center;
  justify-content: center;
}

.centerSelf {
  align-self: center;
}

.classy-modal {
  &__row {
    &-top {
      margin-top: 20px;
    }
  }
  &__footer {
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    &-left {
      font-size: 12px;
      text-align: left;
    }
    &--small {
      font-size: 12px;
    }
  }
}

.contentRight {
  justify-content: flex-end;
}

.contentSpaced {
  justify-content: space-between;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.form-control-feedback {
  z-index: 0;
  top: 1px;
}

.has-feedback label ~ .form-control-feedback {
  top: 27px;
}

.gutter {
  margin: 15px;
}

.hide {
  display: none;
}

.padding-horizontal {
  padding: 0 15px 0 15px;
}

.indented {
  margin-left: 15px;
}

.margin-bottom-large {
  margin-bottom: 15px;
}

.margin-horizontal-large {
  margin: 0 15px 0 15px;
}

.margin-vertical-large {
  margin: 15px 0 15px 0;
}

.outline {
  border: 1px solid grey;
}

.padding-small {
  padding: 5px;
}

.padding-medium {
  padding: 10px;
}

.padding-large {
  padding: 15px;
}

.password-form {
  max-width: 300px;
  width: 100%;

  .form-group {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    padding: 18px 12px;
    border-radius: 30px;
    margin: 10px;
  }

  button {
    margin: 15px 0 15px 0;
    width: 100%;
  }
}

.required-marker {
  color: $coral;
}

.status-icon--error {
  color: red;
}

.status-icon--success {
  color: green;
  opacity: 0.8;
}

.title-text {
  font-size: 2.8em;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
}

.top-nav {
  display: flex;
  background-color: $whitesmoke;
  height: 100px;
  width: 100vw;
  justify-content: flex-end;
  align-items: center;
}

.test-env-highlight {
  background-color: $coral;
}
