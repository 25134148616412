@import '../../Themes/_colors.scss';

.organizationId {
  .valid-org-id {
    color: green;
  }
  .invalid-org-id {
    color: red;
  }

  .tiny-loader {
    position: absolute;
    right: 6px;
    top: 32px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid $coral;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    background-color: #fff;
    z-index: 5;
    animation: spin 2s linear infinite;
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  .invalid-message {
    color: red;
    font-size: 10px;
    padding-top: 2px;
  }

  input:-webkit-autofill + .tiny-loader {
    background-color: #e8f0fe;
  }
}
