@import '../../Themes/_colors.scss';

.program-designations {
  &__table {
    .rt-th.-cursor-pointer > div {
      color: $whitesmoke;
      line-height: 2em;
    }
  }

  &__table-container {
    margin-top: 30px;
  }

  &__table-header-button {
    margin-right: 15px;
  }

  &__table-header-button-group {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
  }
}
