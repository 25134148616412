@import '../../../Themes/colors';

.required-field {
  &.has-error {
    > div > div {
      border-color: $errorText;
    }

    .select-control > div {
      border-color: $errorText;
    }

    input {
      border-color: $errorText;
    }
  }

  &.has-success {
    > div > div {
      border-color: $successText;
    }

    .select-control > div {
      border-color: $successText;
    }
  }
}
