@import '../../../Themes/_colors.scss';

.queues {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%/3, max(270px, 100%/5)), 1fr));
  column-gap: 20px;
  row-gap: 20px;
  margin: 0px auto;
}

.queues_modal {
  &__warning {
    border: 1px solid $errorText;
    background-color: $inactive;
    padding: 10px;
    border-radius: 4px;

    h2 {
      margin: 0px 0px 10px;
      font-size: 22px;
      font-weight: 600;
      color: $errorText;
    }

    p {
      margin-bottom: 0px;
    }
  }

  h3 {
    margin-top: 40px;
    font-weight: 600;
    font-size: 22px;
  }
}

.queue {
  &__container {
    display: grid;
    border: 1px solid $border;
    margin: 10px;
    grid-template-columns: auto-fit 100px;

    div {
      border-collapse: collapse;
      border-bottom: 1px solid $border;
      padding: 10px;
    }
  }

  &__header {
    grid-column: 1/3;
    font-family: 'Muli', sans-serif;
    font-size: 1.5em;
  }

  &__footer {
    grid-column: 1/3;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    > button {
      margin-bottom: 6px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  &__count {
    grid-column: 2/3;
    text-align: center;

    .active {
      background-color: mediumspringgreen;
      color: #fff;
    }

    .warning {
      background-color: orange;
      color: #fff;
    }

    .error {
      background-color: red;
      color: #fff;
    }

    .neutral {
      background-color: grey;
      color: #fff;
    }
  }

  &__status {
    grid-column: 1/2;
    font-weight: 600;
  }
}

.queueheader {
  display: grid;
  font-weight: 600;
  margin-bottom: 10px;
  grid-template-columns: 100px 150px;

  div {
    padding: 10px;
  }
}
