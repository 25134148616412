@import '../../Themes/_colors.scss';
.classyradio-container {
  margin-top: 15px;
  .classyradio {
    display: inline-block;
    padding-right: 50px;
    input {
      display: none;
    }

    label {
      font-weight: normal;
      &::before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        border: 2px solid white;
        box-shadow: 0px 0px 0px 1px #000000;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        margin-bottom: 3px;
      }
    }

    input:checked + label {
      &::before {
        background: $coral;
      }
    }
  }
}
