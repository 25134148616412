@import '../../Themes/_colors.scss';

.recurring-donation-migrations {
  &__table {
    .rt-td {
      //background-color: white;
    }
    .rt-tbody,
    .rt-thead {
      min-width: 1260px !important;
    }

    .rt-tbody {
      overflow: auto;
      max-height: 58vh;
    }
  }

  &__table-container {
    margin-top: 30px;
  }

  &__input-date,
  &__input-text {
    background-color: $light;
    border: 1px solid $border;
    border-radius: 4px;
    height: 38px;
    margin-right: 10px;

    &__orgid {
      width: 75px;
    }

    &__label {
      width: 175px;
    }
  }
}

.processing-job {
  color: gray;
}

.input-invalid {
  background-color: pink;
  outline: none;
}

.file-selector {
  width: 200px;
}

.center-column-data {
  display: flex;
  justify-content: center;
}

.badge {
  padding: 3px 8px;
  margin: auto;
  &.total-success {
    background-color: mediumspringgreen;
    color: #fff;
  }

  &.total-warning {
    background-color: orange;
    color: #fff;
  }

  &.total-error {
    background-color: red;
    color: #fff;
  }
}

.recurring-migrations-wrapper {
  position: relative;
  .jobs-loader {
    position: absolute;
    right: 6px;
    top: -32px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid $coral;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    background-color: #fff;
    z-index: 5;
    animation: spin 2s linear infinite;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
  .job-label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /*
	.rt-table {
	 	max-height: 650px;
	 	overflow: auto;
	}
	*/
}
