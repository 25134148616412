@import '../../Themes/_colors.scss';

.dropdown {
  width: 100%;

  &-menu {
    li a {
      white-space: normal;
    }
  }

  &-toggle {
    overflow: hidden;
  }

  &-toggle::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}

.navbar-default .navbar-nav > li > a {
  &:hover {
    color: $moderateCyan;
  }
}

#organizationsDropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  text-align: left;
  width: 100%;
}

.panel {
  cursor: pointer;
}

.panel-body > a {
  color: gray;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border: 0;
}

.panel-collapse {
  .panel-body {
    padding: 0 15px 10px 15px;
  }
}

.panel-title {
  color: gray;

  > a {
    text-decoration: none;
  }

  > a[aria-expanded='true'] {
    text-decoration: none;
    color: $moderateCyan;
  }

  &:hover {
    color: $moderateCyan;
    text-decoration: none;
  }
}

.sidenav {
  width: 250px;
  height: 100%;
  overflow-y: auto;

  #organizationsDropdown.d-none {
    display: none;
  }

  .panel-collapse {
    margin-left: 15px;

    .panel {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }

    span {
      color: gray;
      font-size: 0.9em;

      &:hover {
        color: black;
        cursor: pointer;
      }
    }
  }

  .panel-default {
    border-color: transparent;

    > .panel-heading {
      background-color: transparent;
      background-image: none;
      text-decoration: none;
    }
  }

  .panel-group {
    margin-top: 20px;
  }

  &__brand {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &__brand-logo {
    align-self: center;
    height: 36px;
  }

  &__nav-link {
    padding: 0;
    margin-left: -20px;

    &:hover {
      color: $moderateCyan;
      text-decoration: none;
    }
  }

  &__panel-body {
    margin-left: 20px;
    padding: 10px 15px;

    a {
      color: gray;
      margin-left: -20px;
    }
  }
}
