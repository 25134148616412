@import '../../Themes/_colors.scss';

.input-valid {
  background-color: $light;

  &:focus {
    outline: none;
  }
}

.input-invalid {
  background-color: pink;
  outline: none;
}
