@import '../../Themes/_colors.scss';

.rlcDesignations {
  &__toggle_button {
    width: 150px;
  }
  &__confirmation {
    font-style: italic;
    color: red;
    margin-bottom: 20px;
  }
  &__form {
    margin-bottom: 0;
  }
  &__error_text {
    color: red;
  }
  &__throbber_container {
    span:first-child {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
