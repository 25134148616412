#newPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  ul {
    font-size: 0.9em;
    margin-left: -5px;
  }
}
