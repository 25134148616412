@import '../../Themes/_colors.scss';

.logDetailFailure {
  > .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    > h4 {
      margin-right: 10px;
    }
  }
}
