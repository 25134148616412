.app {
  height: 100vh;
}

.routeContainer {
  margin-left: 250px;
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
