@import '../../Themes/_colors.scss';
.virtualTerminal__iframe {
  height: 38px;
}

.virtualTerminal__iframe.loading {
  border-radius: 4px;
  border: 1px solid $border;
  margin: 0;
  width: 100%;
  font-size: 14px;
  padding: 9px 10px;
  color: #555;

  div {
    display: none;
  }
}
