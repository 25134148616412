@import '../../Themes/_colors.scss';

.classy-table {
  margin-bottom: 30px;

  &__checkbox {
    input[type='checkbox'] {
      margin: 12px 0;
    }
  }

  &__footer-left {
    display: flex;
    justify-content: space-between;

    button {
      align-self: center;
    }
  }
}

.ReactTable .-pagination {
  background-color: $light;
  border-top-width: 1px;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;

  button:disabled {
    border: 1px solid $disabled;
    color: $disabled;

    &:hover {
      background-color: white;
    }
  }

  input,
  select {
    border: 1px solid $dark;
    border-radius: 0;
    padding: 5px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    background-position: 90% 50%;
    background-repeat: no-repeat;
    background-size: 16px;
    height: 35px;
    padding: 5px 20px;
    width: 120px;
  }

  .-center {
    //flex: .7 1;
    margin: 0 20px;
    flex-wrap: nowrap;
  }

  .-next {
    text-align: left;
  }

  .-previous {
    text-align: right;
  }
}

.ReactTable .rt-tbody .rt-tr-group {
  padding: 5px 0;
}

.ReactTable .rt-thead.-header {
  background-color: $tableHeaderBackground;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  color: white;
  text-align: left;
}

.ReactTable .rt-td,
.ReactTable .rt-thead .rt-th {
  height: 44px;
  padding: 15px;
  display: flex;
  align-items: center;

  .non-editable-cell {
    padding: 5px;
    text-align: center;
  }
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  background-color: $darkHighlight;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

.ReactTable .rt-noData {
  display: none;
}

.ReactTable .rt-resizer {
  right: 0;
}
